.sql {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .prompt {
        height: 10em;
    }

    .execution-time {
        font-size: small;
        text-align: right;
        margin-top: 5px;
        margin-bottom: 15px;
    }

    .result {
        max-height: 100%;
        width: 100%;
        overflow-y: scroll;
        flex: 1;

        .no-results, .error {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .error {
            color: red;
        }

        .not-shown {
            margin: 20px;
        }
    }

    .schema-header {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .schema {
        overflow-y: scroll;
        max-height: 100%;
        font-family: monospace;
        flex: 1;
        background-color: white;
    }
}