.topsong {
    border-collapse: collapse;
    width: 100%;

    td,
    th {
        border: 1px solid #ddd;
        border-top: 0;
        padding: 8px;
    }

    tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    tr:hover {
        background-color: #ddd;
    }

    th {
        position: sticky;
        top: 0;
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #727272;
        color: white;
    }
}