.dashboard {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    gap: 20px;

    .widget {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid black;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0px 0px 3px black;
        align-items: center;
        text-align: center;
        height: 7.5em;
        aspect-ratio: 1 / 1;

        .before {
            flex: 1;
        }

        .big {
            font-size: 30px;
            color: #3eabf3;
        }

        .after {
            flex: 1;
            display: flex;
            flex-direction: column-reverse;
        }
    }
}