.chart-and-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    height: 100%;
    .chart {
        width: 100%;
        min-height: 0;
        flex: 1
    }

    .table {
        width: 100%;
        overflow: scroll;
        flex: 2
    }
}