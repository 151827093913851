.main {
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .tabs {
        display: flex;
        background-color: gray;

        a {
            border: 1px solid gray;
            margin: 5px;
            padding: 3px;
            border-radius: 5px;
            text-decoration: none;
            color: darkgrey;
        }

        .active {
            color: darkblue
        }
    }

    .inner {
        min-height: 0;
        height: 100%;
    }
}