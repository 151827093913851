.welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;


    .center {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: auto;
        margin-bottom: auto;

        p {
            font-size: 20px;
            text-align: center;
        }

        .uploadbutton {
            margin: 5px;
            cursor: pointer;
            border: 1px solid gray;
            margin: 5px;
            padding: 10px;
            border-radius: 7px;
            text-decoration: none;
            font-size: 20px;

            input {
                display: none;
            }
        }
    }
    
    .helplink {
        margin-top: auto;
        margin-bottom: 20px;
        font-size: 16px;
    }
}